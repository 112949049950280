<template>
  <div class="flex">
    <Popper
      class="locale-switcher--popper"
      placement="bottom-end"
      offset-distance="0"
    >
      <div class="flex items-center cursor-pointer">
        <IcLanguage class="text-black" width="20" />
        <div class="underline font-medium mx-2">
          {{ localePathEntity.getLocaleName(currentLocale) }}
        </div>
        <IcChevronDown class="text-black" width="10" />
      </div>

      <template #content="{ close }">
        <div class="locale-switcher">
          <div class="locale-switcher__header">
            <IcClose class="locale-switcher__ic-close" width="36" @click="close" />
            <span class="font-bold"> {{ $t('navbar.language') }} </span>
          </div>
          <div
            v-for="locale in availableLocales"
            :id="`gtm-desktop-save-locale-${locale}`"
            :key="`locale-${locale}`"
            class="locale-switcher__option"
            @click.prevent="selectNewLanguage(close, locale)"
          >
            <span>{{ localePathEntity.getLocaleName(locale) }}</span>
            <IcCheck v-if="currentLocale === locale" class="text-rs-primary mt-1" width="18" height="13" />
          </div>
          <div class="h-10" />
        </div>
      </template>
    </Popper>
  </div>
</template>

<script setup lang="ts">
import Popper from 'vue3-popper'
import useLocale from '~/composables/useLocale'
import { useLocalePathEntity } from '~/composables/entities'
import { IcLanguage, IcChevronDown, IcClose, IcCheck }  from '~/components/common/icons'

const { currentLocale, availableLocales } = useLocale()
const localePathEntity = useLocalePathEntity()

function selectNewLanguage (closeFunc: Function, newLocale: string) {
  closeFunc()
  localePathEntity.selectNewLanguage(newLocale)
}
</script>

<style scoped>
.locale-switcher {
  @apply bg-white flex flex-col w-64 divide-y divide-gray-200;
}
.locale-switcher__header {
  @apply relative text-center border-b border-gray-400 p-4;
}
.locale-switcher__ic-close {
  @apply cursor-pointer absolute left-4 top-2.5 text-rs-primary;
}
.locale-switcher__option {
  @apply p-4 cursor-pointer flex justify-between hover:bg-rs-primary hover:bg-opacity-20;
}

.locale-switcher--popper :deep(.popper) {
  @apply !rounded-md overflow-hidden !shadow !bg-white;
  @apply !my-3;
  border: 1px solid #ddd !important;
}
</style>

