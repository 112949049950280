const getApiRegex = (apiUrl: string, localized = true) => {
  if (localized) {
    return new RegExp(`^\/(de|en|es|fr|pt|it|sv|nl)${apiUrl}$`, 's')
  } else {
    return new RegExp(`^${apiUrl}$`, 's')
  }
}

export {
  getApiRegex,
}
