import { getApiRegex } from '~/services/api/apiRegex'

export default {
  'supportTree.get': {
    uri: (locale: string) => `/${locale}/support-tree/roots`,
    method: 'get',
    regex: getApiRegex('\/support-tree\/roots'),
  },
  'supportTree.getById': {
    uri: (locale: string) => `/${locale}/support-tree/roots/:rootId`,
    method: 'get',
    regex: getApiRegex('\/support-tree\/roots(\/(\\w|-)+)'),
  },
}
