import { Ref } from 'vue'

const loadingStack: { [key: string]: Ref<number> } = {
  global: ref(0),
}

export default function (key = 'global') {
  const isLoading = computed(() => {
    return loadingStack[key].value > 0
  })

  const startLoading = () => {
    if (!loadingStack[key]) {
      loadingStack[key] = ref(0)
    }
    loadingStack[key].value += 1
  }

  const stopLoading = () => {
    if (loadingStack[key]) {
      loadingStack[key].value -= 1
    }
  }

  const forceStopLoading = () => {
    if (loadingStack[key]) {
      loadingStack[key].value = 0
      return
    }
    loadingStack[key] = ref(0)
  }

  return {
    startLoading,
    stopLoading,
    forceStopLoading,
    isLoading,
  }
}
