<template>
  <footer id="footer">
    <div class="container">
      <div class="footer-wrapper">
        <div class="footer-logo">
          <RsLogo class="h-10" />
        </div>

        <hr class="my-4 desktop:my-0 border-black">

        <div class="footer-menu">
          <div class="flex-none text-sm text-bold mr-4 mb-4 md:mb-0">
            <p>&copy; Roadsurfer {{ currentYear }}</p>
          </div>

          <section class="flex-1 py-1 desktop:py-0">
            <ul class="footer-links">
              <li v-if="isGerman" class="inline-block font-semibold mr-2">
                <a href="https://roadsurfer.com/de/impressum/" target="_blank">Impressum</a>
              </li>
              <li class="inline-block font-semibold mr-2">
                <a :href="$t('footer.privacyLink')" target="_blank">{{ $t('footer.privacy') }}</a>
              </li>
              <li class="inline-block font-semibold mr-2">
                <a :href="$t('footer.termConditionLink')" target="_blank">{{ $t('footer.termCondition') }}</a>
              </li>
              <li v-if="isGerman" class="inline-block font-semibold mr-2">
                <a href="https://roadsurfer.com/de/camper-abo/agb/" target="_blank">Abo-AGB</a>
              </li>
              <li v-if="isEnglish" class="inline-block font-semibold mr-2">
                <a href="https://roadsurfer.com/en-us/terms-and-conditions/website/" target="_blank">{{ $t('footer.termOfUse') }}</a>
              </li>
              <li class="inline-block font-semibold">
                <a href="#" @click.prevent="handleUsercentrics">{{ $t('footer.cookieSettings') }}</a>
              </li>
            </ul>
          </section>
        </div>
      </div>

      <section class="footer-disclaimer">
        <p>{{ $t('footer.copyRight', { year: currentYear }) }}. {{ $t('footer.roadsurferAddress') }}</p>
        <p>{{ $t('footer.disclaimer') }}</p>
      </section>
    </div>
  </footer>
</template>

<script setup lang="ts">
import RsLogo from '~/components/core/RsLogo.vue'

const { currentLocale } = useLocale()

const currentYear = computed (() => new Date().getFullYear())
const isGerman = computed(() => currentLocale.value === 'de')
const isEnglish = computed(() => currentLocale.value === 'en')
const handleUsercentrics = () => { window.UC_UI?.showSecondLayer() }
</script>

<style scoped>
#footer {
  @apply bg-rs-primary py-4 text-black;
}
.footer-wrapper {
  @apply flex flex-col;
}
.container {
  @apply m-auto px-8;
}
.footer-logo {
  @apply flex items-center;
}
.footer-menu {
  @apply flex flex-col justify-between flex-wrap;
}
.footer-links {
  @apply grid grid-cols-2 gap-4;
  @apply text-sm;
}
.footer-links a {
  @apply text-black !important;
}

.footer-disclaimer {
  @apply text-xs mt-6;
}

@screen md {
  .footer-menu {
    @apply flex-row items-center;
  }
  .footer-links {
    @apply flex flex-wrap justify-end gap-0;
  }
}

@screen xl {
  .footer-wrapper {
    @apply flex-row items-center justify-between;
  }
  .footer-menu {
    @apply flex-row items-center;
  }
  .footer-links {
    @apply flex flex-wrap justify-end gap-0;
  }
}
</style>
