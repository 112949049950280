import { getApiRegex } from '~/services/api/apiRegex'

export default {
  'booking.get': {
    uri: (locale: string) => `/${locale}/bookings/:bookingNumber`,
    method: 'get',
    regex: getApiRegex('\/bookings(\/\\d+)'),
  },
  'booking.checkVehicleChange': {
    uri: '/customer-account/booking-car-change/:bookingNumber/:carId',
    method: 'post',
    regex: getApiRegex('\/customer-account\/booking-car-change(\/\\d+)(\/\\d+)', false),
  },
  'booking.checkDepositMade': {
    uri: '/customer-account/deposit-made/:bookingNumber',
    method: 'get',
    regex: getApiRegex('\/customer-account\/deposit-made(\/\\d+)', false),
  },
  'booking.updateDrivers': {
    uri: '/customer-account/bookings/:bookingNumber/drivers',
    method: 'post',
    regex: getApiRegex('\/customer-account\/bookings(\/\\d+)\/drivers', false),
  },
}
