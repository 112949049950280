import {
  approveTrackingContent,
  checkTrackingContent,
  getBySlug,
  getBySlugPublic,
  getTrackingStatus,
  putTrackingStatus,
  trackStartFinalChecks as _trackStartFinalChecks
} from '~/services/repositories/onBoarding.repository'
import type { PageWithTracking } from '../usecases/useOnboardingFlow'
import { DAMAGE_CONTENT_UUID } from '~/services/constants'
import { OnboardingPageType, TrackingTipStatus } from '~/services/entities'

export default function () {
  const { $api } = useNuxtApp()

  const fetchBySlug = (slug: string, isPublic: boolean = true) => {
    return isPublic ? getBySlugPublic($api, slug) : getBySlug($api, slug)
  }

  const fetchTrackingStatus = (bookingNumber: string) => {
    return getTrackingStatus($api, bookingNumber)
  }

  const updateTrackingStatus = (page: PageWithTracking, bookingNumber: string) => {
    return putTrackingStatus($api, {
      pageId: Number(page.content?.id),
      params: page.params,
    }, bookingNumber)
  }

  const checkTrackingTipStatus = (page: PageWithTracking, tip, status: string, bookingNumber: string) => {
    return checkTrackingContent($api, {
      pageUuid: page.content!.uuid,
      contentUuid: tip.uuid,
      status,
    }, bookingNumber)
  }
  const approveTrackingTipStatus = (page: PageWithTracking, tip, status: string, bookingNumber: string) => {
    return approveTrackingContent($api, {
      pageUuid: page.content!.uuid,
      contentUuid: tip.uuid,
      status,
    }, bookingNumber)
  }

  const doneDamageCheck = (page: PageWithTracking, bookingNumber: string) => {
    return approveTrackingContent($api, {
      pageUuid: page.content!.uuid,
      contentUuid: DAMAGE_CONTENT_UUID,
      status: TrackingTipStatus.DONE,
    }, bookingNumber)
  }

  const trackStartFinalChecks = (bookingNumber: string) => {
    return _trackStartFinalChecks($api, bookingNumber)
  }

  return {
    fetchBySlug,
    fetchTrackingStatus,
    updateTrackingStatus,
    checkTrackingTipStatus,
    approveTrackingTipStatus,
    doneDamageCheck,
    trackStartFinalChecks,
  }
}
