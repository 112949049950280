import { type Session } from "~/services/entities"
import { transformAxiosResponse } from "./base.repository"

const transformDataToSession = (data: any = {}): Session => {
  return {
    bookingNumber: data.customer.booking_number,
  }
}

export const getSession = async (apiEngine: any, token: string) => {
  const { response } = await apiEngine.call('session.get', null, { params: { token } })
  return transformAxiosResponse(response, transformDataToSession)
}
