import { defineStore } from 'pinia'

import { type Booking, type Manufacturer, type Onboarding } from '~/services/entities'
import { useBookingEntityHelpers } from '~/composables/entityHelpers'

export const useManufacturersStore = defineStore('manufacturers', () => {
  const manufacturers = ref<Manufacturer[]>([])
  const bookingManufacturer = ref<Manufacturer | null>(null) // booking merged manufacturer model
  const bookingManufacturerModel = ref<Manufacturer | null>(null) // booking manufacturer model

  const initManufacturersStore = ({ onboarding, booking }: {
    onboarding: Onboarding,
    booking?: Booking,
  }) => {
    const { isBookingPickingUpOrPickedUp, getHighlightedManufacturerModel } = useBookingEntityHelpers()

    const highlightedManufacturerModel = booking ? getHighlightedManufacturerModel(booking) : null

    bookingManufacturer.value = onboarding.manufacturers
      .find((manufacturer) => manufacturer.models.find(model => model.id === highlightedManufacturerModel)) || null
    manufacturers.value = onboarding.manufacturers.sort((manufacturer1, manufacturer2) => {
      const manufacturer1Name = manufacturer1.modelName
      const manufacturer2Name = manufacturer2.modelName
      if (manufacturer1Name === bookingManufacturer.value?.modelName) {
        return -1
      }
      if (manufacturer2Name === bookingManufacturer.value?.modelName) {
        return 1
      }
      return manufacturer1Name > manufacturer2Name ? 1 : -1
    })

    if (booking && bookingManufacturer.value) {
      if (isBookingPickingUpOrPickedUp(booking)) {
        const specificModel = bookingManufacturer.value.models.find(
          model => model.id === highlightedManufacturerModel
        )

        bookingManufacturerModel.value = {
          ...bookingManufacturer.value,
          modelName: specificModel?.modelName ?? bookingManufacturer.value.modelName
        }
      }
    }
  }

  return {
    manufacturers,
    bookingManufacturer,
    bookingManufacturerModel,

    initManufacturersStore,
  }
})
