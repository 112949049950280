<template>
  <RsButton variant="secondary" class="w-full" @click.prevent="logoutCA">
    {{ $t('navbar.logout') }}
  </RsButton>
</template>

<script setup lang="ts">
import useAuth from '~/composables/auth/useAuth'
import useLoading from '~/composables/useLoading'

const emit = defineEmits(['close'])

const { $router } = useNuxtApp()
const { logout } = useAuth()
const { startLoading, stopLoading } = useLoading()

const logoutCA = async () => {
  startLoading()
  emit('close')

  try {
    await logout()
    $router.go()
  } catch {
    stopLoading()
  }
}
</script>
