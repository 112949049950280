import { BOOKING_VERSION_API_HEADER } from '~/services/constants'

// Reference AxiosResponse
export interface APIResponse<D> {
  data: D
  status: number
  statusText: string
  bookingVersion: string
}

export const transformAxiosResponse = <D>(response, dataTransformFunction: (data: any) => D): APIResponse<D> => {
  const data = dataTransformFunction(response.data)
  return {
    data,
    status: response.status,
    statusText: response.statusText,
    bookingVersion: response?.headers[BOOKING_VERSION_API_HEADER.toLowerCase()],
  }
}
