<template>
  <div @click="openNavbar">
    <IcUser class="text-black cursor-pointer" width="22" />
  </div>
</template>

<script setup lang="ts">
import { IcUser }  from '~/components/common/icons'
const { handleModal } = useModal()

const openNavbar = () => handleModal('modal-navbar')
</script>
