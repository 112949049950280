import dayjs from 'dayjs'

import { Booking, BookingState,BookingSubType } from '~/services/entities'

export const useBookingEntityHelpers = () => {
  const isBookingPickingUp = (booking: Booking) => {
    const today = dayjs().startOf('day')
    return today.isSame(booking.agreedPickupDatetime, 'day') || today.isAfter(booking.agreedPickupDatetime, 'day')
  }

  const isBookingPickedUp = (booking: Booking) => {
    return [
      BookingState.ON_GOING,
      BookingState.RETURNED,
    ].includes(booking.state)
  }

  const isBookingPickingUpOrPickedUp = (booking: Booking) => {
    const isPickedUp = isBookingPickedUp(booking)
    const isPickupUpDay = isBookingPickingUp(booking)

    return isPickedUp || isPickupUpDay
  }

  const getHighlightedManufacturerModel = (booking: Booking) => {
    if (isBookingPickingUpOrPickedUp(booking)) {
      return booking.manufacturerModel
    }

    return booking.firstTrackedManufacturerModel || booking.manufacturerModel
  }

  const isBusinessTripBooking = (booking: Booking) => booking.subType === BookingSubType.BUSINESS_TRIP
  const isSubscriptionBooking = (booking: Booking) => booking.isSubscription

  const shouldSkipDepositValidation = (booking: Booking) => isBusinessTripBooking(booking) || isSubscriptionBooking(booking)

  return {
    isBookingPickingUp,
    isBookingPickedUp,
    isBookingPickingUpOrPickedUp,
    getHighlightedManufacturerModel,
    shouldSkipDepositValidation
  }
}
