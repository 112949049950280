export enum OnboardingPageType {
  ONBOARDING = 'ONBOARDING',
  CERTIFICATE = 'CERTIFICATE',
  EQUIPMENT_CHECK = 'EQUIPMENT_CHECK',
  HELP_AND_RETURN = 'HELP_AND_RETURN',
  FINAL_CHECK = 'FINAL_CHECK',
  FINAL_CHECK_V2 = 'FINAL_CHECK_V2',
  CONGRATULATION = 'CONGRATULATION',
  THANK_YOU = 'THANK_YOU',
  LINKS = 'LINKS',
  SUMMARY = 'SUMMARY',
  SUPPORT = 'SUPPORT_TREE'
}

export enum OnboardingPageContentType {
  SUBHEADLINE = 'SUBHEADLINE',
  TEXTBLOCK = 'TEXTBLOCK',
  TEXTBLOCK_BULLET_POINT = 'TEXTBLOCK_BULLET_POINT',
  BULLET_POINTS = 'BULLET_POINTS',
  NUMBERED_LIST = 'NUMBERED_LIST',
  HINT = 'HINT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  MODEL_GROUP = 'MODEL_GROUP',
  CONTENT_TIPS = 'CONTENT_TIPS',
  TIPS = 'TIPS',
}

export enum OnboardingPageLinkType {
  HELPER_LINKS = 'HELPER_LINKS',
  URL_CAMPER = 'URL_CAMPER',
  URL_360_VIEW = 'URL_360_VIEW',
  URL_HELPCENTER = 'URL_HELPCENTER',
}

export interface OnboardingPageContent {
  id: number
  type: OnboardingPageContentType
  value: any
  uuid: string
  attachments: Array<{
    attachment: {
      id: string
      originalFileName: string
      url: string
    },
    caption: string
  }>
  manufacturers: Array<string>,
  nestedShowing?: Array<OnboardingPageContent>,
  nestedNotShowing?: Array<OnboardingPageContent>
}

export interface OnboardingPageLink {
  id: number
  url: string
  text: string
  type: OnboardingPageLinkType
  manufacturers: Array<string>
}

export interface OnboardingPage {
  id: number
  uuid: string
  name: string
  position: number
  version: number
  type: OnboardingPageType
  contents?: OnboardingPageContent[]
  pageLinks: OnboardingPageLink[]
}

export interface Manufacturer {
  id: string
  models: Array<{ id: string, modelName: string }>
  modelName: string
}

export interface Onboarding {
  id: number
  name: string
  slug: string
  pdf: string
  onboardingPages: OnboardingPage[]
  manufacturers: Array<Manufacturer>
  links: OnboardingPageLink[]
  supportTreeRoots: Array<string>
  hasSupportTrees: Boolean
}

export enum TrackingTipStatus {
  CHECKED = 'checked',
  HAVE_QUESTION = 'have_question',
  MISSING = 'missing',
  AVAILABLE = 'available',
  ACCEPT = 'accept',
  DONE = 'done',
}

export interface TrackingStatus {
  uuid: string,
  seen: boolean,
  type: OnboardingPageType,
  params: Object,
  content: Object,
}
