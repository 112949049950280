import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE } } = useRuntimeConfig()

  if (!SENTRY_DSN) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],

    tracesSampleRate: 0.2, // Change in prod
  })
})
