import isEmpty from 'lodash/isEmpty.js'
import omit from 'lodash/omit.js'

const serializeCustomerSettings = (settings, language) => {
  if (!language) {
    return settings
  }

  const newLanguage = {
    key: 'communication_language',
    value: language,
    type: 'string',
  }

  if (isEmpty(settings)) {
    return [newLanguage]
  }

  return settings.map((setting: any) => {
    if (setting.key === 'communication_language') {
      return newLanguage
    }
    return setting
  })
}

const serializeCustomer = (customer) => {
  const settings = serializeCustomerSettings(customer.settings, customer.language)

  return {
    ...omit(customer, ['full_name', 'id', 'code', 'language']),
    settings,
  }
}

export default function () {
  const { $api } = useNuxtApp()

  const updateMainDriver = (
    bookingNumber: number,
    mainDriver: any,
    drivers: any,
    bookingVersion: string,
  ) => {
    return $api.call(
      'booking.updateDrivers',
      { bookingNumber },
      {
        data: {
          customer: serializeCustomer(mainDriver),
          drivers,
        },
        bookingVersion,
      },
    )
  }

  return {
    updateMainDriver,
  }
}
