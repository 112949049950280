export default function () {
  const showModal = (type: string, props: any) => {
    const event = new CustomEvent('modal:show', {
      detail: { type, props },
    })
    window.document.dispatchEvent(event)
  }

  const hideModal = (type: string) => {
    const event = new CustomEvent('modal:hide', {
      detail: { type },
    })
    window.document.dispatchEvent(event)
  }

  const handleModal = (type: string, props: any) => {
    return new Promise((resolve, reject) => {
      showModal(type, props)

      const handler = (event: CustomEvent) => {
        if (type === event.detail.modal.type) {
          if (event.detail.isSuccess) {
            resolve(event.detail.data)
          } else {
            reject(new Error('dismissed'))
          }
          hideModal(type)

          window.document.removeEventListener('modal:commit', handler as EventListener)
          window.document.removeEventListener('modal:dismiss', handler as EventListener)
        }
      }

      window.document.addEventListener('modal:commit', handler as EventListener)
      window.document.addEventListener('modal:dismiss', handler as EventListener)
    })
  }

  return {
    handleModal,
    hideModal,
  }
}
