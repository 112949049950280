import de from './translations/de.json'
import en from './translations/en.json'
import es from './translations/es.json'
import fr from './translations/fr.json'
import it from './translations/it.json'
import nl from './translations/nl.json'
import pt from './translations/pt.json'
import sv from './translations/sv.json'

export function removeEmptyTranslations (obj: any) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      removeEmptyTranslations(obj[key])
    } else if (!obj[key].trim()) {
      delete obj[key]
    }
  }
}

export const LOCALE_NAMES: Record<string, string> = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  nl: 'Nederlands',
  pt: 'Português',
  sv: 'Svenska',
}
export const AVAILABLE_LOCALES = Object.keys(LOCALE_NAMES)

removeEmptyTranslations(de)
removeEmptyTranslations(es)
removeEmptyTranslations(fr)
removeEmptyTranslations(it)
removeEmptyTranslations(nl)
removeEmptyTranslations(pt)
removeEmptyTranslations(sv)

export const localeMessages = {
  de,
  en,
  es,
  fr,
  it,
  nl,
  pt,
  sv,
}

export const NUMBER_FORMATS = {
  currency: {
    style: 'currency', currency: 'EUR', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 2, maximumFractionDigits: 2,
  },
  shortCurrency: {
    style: 'currency', currency: 'EUR', currencyDisplay: 'narrowSymbol', minimumFractionDigits: 0, maximumFractionDigits: 0,
  },
  number: {
    minimumFractionDigits: 2, maximumFractionDigits: 2,
  },
}

export const DATETIME_FORMATS = {
  date: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  time: {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  },
  dateTime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  },
}
