<template>
  <component :is="variant" :class="className">
    <slot />
  </component>
</template>

<script setup lang="ts">
const props = defineProps({
  variant: {
    type: String,
    default: 'h1',
    validator: (prop: string) => [
      'h1',
      'h2',
      'h3',
    ].includes(prop),
  },
})

const { variant } = toRefs(props)
const className = computed(() => {
  const classes = {
    h1: 'text-h1 font-semibold',
    h2: 'text-xl font-semibold',
    h3: 'text-lg',
  }
  return classes[variant.value]
})
</script>
