import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  if (config.public.GTM_ID) {
    const gtm = createGtm({
      id: config.public.GTM_ID,
      compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      debug: true,
      loadScript: true,
    })

    nuxtApp.vueApp.use(gtm)
  }
})
