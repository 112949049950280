import { useI18n } from 'vue-i18n'

export default function () {
  const i18n = useI18n()
  const currentLocale = computed(() => i18n.locale.value)
  const availableLocales = i18n.availableLocales

  const switchCurrency = (currencyCode) => {
    const currentCurrency = i18n.getNumberFormat(currentLocale.value)
    if (currentCurrency.currency === currencyCode) {
      return
    }

    const opt = { style: 'currency', currency: currencyCode, currencyDisplay: 'symbol' }
    availableLocales.forEach((locale) => {
      i18n.setNumberFormat(locale, {
        currency: {
          ...opt, minimumFractionDigits: 2, maximumFractionDigits: 2,
        },
        shortCurrency: {
          ...opt, minimumFractionDigits: 0, maximumFractionDigits: 0,
        },
      })
    })
  }

  return {
    i18n,
    currentLocale,
    availableLocales,
    switchCurrency,
  }
}
