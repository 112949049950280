
// @ts-nocheck


export const localeCodes =  [
  "en",
  "fr",
  "de",
  "pt",
  "es",
  "it",
  "sv",
  "nl"
]

export const localeLoaders = {
  "en": [],
  "fr": [],
  "de": [],
  "pt": [],
  "es": [],
  "it": [],
  "sv": [],
  "nl": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en",
      "name": "English",
      "flag": "ic-en-flag.svg"
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "name": "Français",
      "flag": "ic-fr-flag.svg"
    },
    {
      "code": "de",
      "iso": "de-DE",
      "name": "Deutsch",
      "flag": "ic-de-flag.svg"
    },
    {
      "code": "pt",
      "iso": "pt-PT",
      "name": "Português",
      "flag": "ic-pt-flag.svg"
    },
    {
      "code": "es",
      "iso": "es-ES",
      "name": "Español",
      "flag": "ic-es-flag.svg"
    },
    {
      "code": "it",
      "iso": "it-IT",
      "name": "Italiano",
      "flag": "ic-it-flag.svg"
    },
    {
      "code": "sv",
      "iso": "sv-SE",
      "name": "Svenska",
      "flag": "ic-sv-flag.svg"
    },
    {
      "code": "nl",
      "iso": "nl-NL",
      "name": "Nederlands",
      "flag": "ic-nl-flag.svg"
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en",
    "name": "English",
    "flag": "ic-en-flag.svg",
    "files": []
  },
  {
    "code": "fr",
    "iso": "fr-FR",
    "name": "Français",
    "flag": "ic-fr-flag.svg",
    "files": []
  },
  {
    "code": "de",
    "iso": "de-DE",
    "name": "Deutsch",
    "flag": "ic-de-flag.svg",
    "files": []
  },
  {
    "code": "pt",
    "iso": "pt-PT",
    "name": "Português",
    "flag": "ic-pt-flag.svg",
    "files": []
  },
  {
    "code": "es",
    "iso": "es-ES",
    "name": "Español",
    "flag": "ic-es-flag.svg",
    "files": []
  },
  {
    "code": "it",
    "iso": "it-IT",
    "name": "Italiano",
    "flag": "ic-it-flag.svg",
    "files": []
  },
  {
    "code": "sv",
    "iso": "sv-SE",
    "name": "Svenska",
    "flag": "ic-sv-flag.svg",
    "files": []
  },
  {
    "code": "nl",
    "iso": "nl-NL",
    "name": "Nederlands",
    "flag": "ic-nl-flag.svg",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
