export function generateUrlWithQuery (query = {}) {
  const localePath = useLocalePath()
  const route = useRoute()
  // TODO: use localePath
  const currentQueries = new URLSearchParams(window.location.search)
  const newQueryKeys = Object.keys(query)
  newQueryKeys.forEach((queryKey) => {
    currentQueries.set(queryKey, query[queryKey])
  })

  const newQuery = {
    ...route.query,
    ...query,
  }

  return `${localePath({ path: route.path, query: newQuery })}`
}

export const boldContent = (rawContent: string, boldTextRegex = /\*(?<boldText>.*?)\*/g) => {
  let result = rawContent || ''
  Array.from(result.matchAll(boldTextRegex)).forEach(match => {
    const { groups: { boldText } } = match
    const fullSubstr = match[0]
    result = result.replace(fullSubstr, `<b>${boldText}</b>`)
  })
  result = result.replaceAll('\n', '<br/>')

  return result
}

export const interpolateVariables = (str: string = '', variables: Object = {}) => {
  return str.replace(/\{\{([^}]+)\}\}/g, (match, group1) => {
    return variables[group1] || ''
  })
}
