<template>
  <button :class="btnClasses">
    <template v-if="loading">
      <div class="btn__loading">
        <img src="/img/btn-loading.svg">
      </div>
    </template>
    <div v-if="$slots.leftIcon" class="btn__left-icon">
      <slot name="leftIcon" />
    </div>
    <div :class="labelClasses">
      <slot />
    </div>
    <div v-if="$slots.rightIcon" class="btn__right-icon">
      <slot name="rightIcon" />
    </div>
  </button>
</template>

<script setup lang="ts">
const props = defineProps({
  variant: {
    type: String,
    default: 'primary',
    validator: (prop: string) => [
      'primary',
      'secondary',
      'inline',
    ].includes(prop),
  },
  loading: Boolean,
  underline: Boolean,
})

const attrs = useAttrs()
const { variant, loading, underline } = toRefs(props)

const isInlineButton = computed(() => variant.value === 'inline')
const btnClasses = computed(() => {
  return [
    variant.value,
    { 'rs-button': !isInlineButton.value },
    { 'rs-button-inline': isInlineButton.value },
    { 'cursor-not-allowed opacity-50': attrs.disabled || loading.value },
  ]
})
const labelClasses = computed(() => {
  return [
    { 'text-rs-primary': isInlineButton.value },
    { underline: underline.value },
  ]
})
</script>

<style scoped>
.rs-button {
  @apply rounded-lg p-4 text-sm font-medium flex items-center justify-center;
}
.rs-button-inline {
  @apply inline-flex items-center;
}
.primary {
  @apply bg-rs-primary text-black hover:bg-opacity-90;
}
.secondary {
  @apply bg-white text-rs-primary border border-rs-primary hover:bg-rs-primary hover:bg-opacity-20;
}
.btn__left-icon {
  @apply mr-2;
}
.btn__right-icon {
  @apply ml-2;
}
.btn__loading {
  @apply mr-1 flex items-center justify-center;
}
.btn__loading > img {
  @apply w-4 h-4;
}
</style>
