import Request from '~/services/api'
import { PUBLIC_API_LIST } from '~/services/constants'
import { useCustomerStore } from '~/stores/customer.store'

class API {
  nuxtApp
  caHost

  constructor (nuxtApp, caHost: string) {
    this.nuxtApp = nuxtApp
    this.caHost = caHost
  }

  /**
   * @param {import('~/services/api/index').API_LIST} method
   * @param {{ [key: string]: any }} [routeParams]
   * @param {import('axios').AxiosRequestConfig} [options]
   */
  async call (method, routeParams, options = {}) {
    const requestInstance = new Request()

    try {
      const response = await requestInstance.call(method, routeParams, options)
      return {
        response,
      }
    } catch (error) {
      this.nuxtApp.$sentryCaptureException?.(error.response)

      if (!PUBLIC_API_LIST.includes(method)) {
        const { status } = error.response

        if (status && [401, 403].includes(status) && window?.location) {
          const customerStore = useCustomerStore(this.nuxtApp.$pinia)
          const { currentRoute } = this.nuxtApp.$router
          const { $i18n } = this.nuxtApp.vueApp.config.globalProperties
          const { fullPath, query } = currentRoute.value

          const loginUrl = customerStore
            ? (customerStore.isPartner ? 'login-partner' : 'login')
            : 'login'
          const fromBOParams = customerStore.isBackOffice ? '&from_bo=true' : ''

          window.location.replace(
            `${this.caHost}/${$i18n.locale || 'de'}/${loginUrl}?token=${query.token}${fromBOParams}&from_external=${fullPath}`
          )
          return
        }
      }

      return {
        error,
      }
    }
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const caHost = config.public.CA_HOST

  const api = new API(nuxtApp, caHost)
  return {
    provide: {
      api,
    },
  }
})
