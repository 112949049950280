import type { InjectionKey, ComputedRef, Ref } from 'vue'
import { Manufacturer } from './entities'

export const CERTIFICATE_PAGE_ID = 9999

export const PUBLIC_API_LIST: string[] = [
  'onBoarding.fetchBySlugPublic',
  'supportTree.get',
  'supportTree.getById'
]

type OnboardingParams = {
  firstName: string
}

export const DAMAGE_CONTENT_UUID = '58186954-69e5-45c2-b20a-7c75b5fb9b84'

// TODO: migrate to store
export const GlobalManufacturersProviderKey = Symbol('GlobalManufacturers') as InjectionKey<ComputedRef<Manufacturer[]>>
export const PDFContextProviderKey = Symbol('PDFContext') as InjectionKey<boolean>
export const FinalCheckPageContextProviderKey = Symbol('FinalCheckPageContext') as InjectionKey<boolean>
export const OnboardingParamsProviderKey = Symbol('OnboardingParamsProviderKey') as InjectionKey<ComputedRef<OnboardingParams>>

export const FinalCheckV2PageCanProceedProviderKey = Symbol('FinalCheckV2PageCanProceed') as InjectionKey<Ref<boolean>>
export const SummaryPageCanProceedProviderKey = Symbol('SummaryPageCanProceed') as InjectionKey<Ref<boolean>>

export const BOOKING_STATE = {
  PREPARED: 'PREPARED',
  RELEASED: 'RELEASED',
  RESERVED: 'RESERVED',
  CANCELLED: 'CANCELLED',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  PENDING_AUTHORISATION: 'PENDING_AUTHORISATION',
  FAILED_PAYMENT: 'FAILED_PAYMENT',
  ON_GOING: 'ON_GOING',
  RETURNED: 'RETURNED',
}

export const BOOKING_VERSION_API_HEADER = 'X-Booking-Version'