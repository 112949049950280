import { type Booking, BookingSubType, type ExtraItem, type OnboardingPage, type OnboardingPageContent, OnboardingPageContentType, OnboardingPageType, TrackingStatus, TrackingTipStatus } from '~/services/entities'
import { type PageWithTracking, type TrackingContentStatuses } from './types'
import { transformOnboardingPageListToPages } from '../useTutorialPageNavigation'
import { useManufacturersStore } from '~/stores/manufacturers.store'
import { DAMAGE_CONTENT_UUID } from '~/services/constants'
import { useBookingEntityHelpers } from '~/composables/entityHelpers'

const checkingPages = ref<PageWithTracking[]>([])
const CHECKING_PAGES = [
  OnboardingPageType.FINAL_CHECK,
  OnboardingPageType.EQUIPMENT_CHECK,
  OnboardingPageType.HELP_AND_RETURN,
  OnboardingPageType.SUMMARY,
]
const checkingFlowContentStatuses = ref<TrackingContentStatuses>({})
const equipmentCheckStatuses = ref<TrackingContentStatuses>({})
const checkingFlowSummaryStatuses = ref<TrackingContentStatuses>({})
const fullTips = ref<OnboardingPageContent[]>([])
const extras = ref<ExtraItem[]>([])
const isPickedUp = ref(false)

export const useCheckingFlow = () => {
  const { $i18n } = useNuxtApp()
  const manufacturersStore = useManufacturersStore()
  const { isBookingPickedUp } = useBookingEntityHelpers()

  const initCheckingPages = ({ onboardingPages, trackings, initialIndex, canStartChecking, booking }: {
    onboardingPages: OnboardingPage[],
    trackings: TrackingStatus[],
    initialIndex: number,
    canStartChecking: boolean,
    booking: Booking,
  }) => {
    isPickedUp.value = isBookingPickedUp(booking)

    let pageIndex = initialIndex - 1
    const pages = transformOnboardingPageListToPages(
      onboardingPages.filter(page => {
        if (page.type === OnboardingPageType.FINAL_CHECK) {
          return (page.contents || []).length > 0
        }
        return CHECKING_PAGES.includes(page.type)
      })
    ).sort(
      (page1, page2) => CHECKING_PAGES.findIndex(type => page1.type === type) - CHECKING_PAGES.findIndex(type => page2.type === type)
    ).map(page => {
      const status = trackings.find(status => status.uuid === page.content?.uuid)
      if (!status) {
        return
      }

      pageIndex++
      if (page.type === OnboardingPageType.FINAL_CHECK) {
        const pageName = `${pageIndex + 1}. ${$i18n.t('lastChecksPage.title')}`
        const contentTips = page.content?.contents?.find(content => content.type === OnboardingPageContentType.CONTENT_TIPS)
        if (contentTips) {
          fullTips.value = contentTips.nestedShowing || []
          const trackingStatuses = status.content?.checking_items ?? []

          checkingFlowContentStatuses.value = tips.value.reduce((accumulator, tip) => {
            accumulator[tip.uuid] = trackingStatuses.find(item => item.uuid === tip.uuid)?.status ?? ''
            return accumulator
          }, {})
        }

        return {
          ...page,
          id: OnboardingPageType.FINAL_CHECK,
          type: OnboardingPageType.FINAL_CHECK,
          name: pageName,
          index: pageIndex,
          disabled: !canStartChecking,
          visited: status.seen,
          seen: status.seen,
          meta: {
            title: pageName,
          },
          params: status.params,
          version: page.version,
        }
      }

      if (page.type === OnboardingPageType.EQUIPMENT_CHECK) {
        const pageName = `${pageIndex + 1}. ${$i18n.t('equipmentCheckPage.title')}`
        extras.value = booking?.extras ?? []
        const trackingStatuses = status.content?.checking_items ?? []

        equipmentCheckStatuses.value = extras.value.reduce((accumulator, extra) => {
          accumulator[extra.uuid] = trackingStatuses.find(item => item.uuid === extra.uuid)?.status ?? ''
          return accumulator
        }, {})

        return {
          ...page,
          id: OnboardingPageType.EQUIPMENT_CHECK,
          type: OnboardingPageType.EQUIPMENT_CHECK,
          name: pageName,
          index: pageIndex,
          disabled: !canStartChecking,
          visited: status.seen,
          seen: status.seen,
          meta: {
            title: pageName,
          },
          params: status.params,
          version: 1,
        }
      }

      if (page.type === OnboardingPageType.HELP_AND_RETURN) {
        return {
          ...page,
          id: OnboardingPageType.HELP_AND_RETURN,
          type: OnboardingPageType.HELP_AND_RETURN,
          name: `${pageIndex + 1}. ${$i18n.t('helpOnTheRoadPage.title')}`,
          index: pageIndex,
          disabled: !canStartChecking,
          visited: status.seen,
          seen: status.seen,
          meta: {
            title: $i18n.t('helpOnTheRoadPage.title'),
          },
          params: status.params,
          version: 1,
        }
      }

      if (page.type === OnboardingPageType.SUMMARY) {
        const pageName = `${pageIndex + 1}. ${$i18n.t('summaryPage.title')}`
        const trackingStatuses = status.content?.approved_items ?? []

        const initialCheckingFlowSummaryStatuses: TrackingContentStatuses = {}
        tips.value.forEach((tip) => {
          const tipStatus = trackingStatuses.find(item => item.uuid === tip.uuid)?.status ?? checkingFlowContentStatuses.value[tip.uuid]

          if (tipStatus) {
            initialCheckingFlowSummaryStatuses[tip.uuid] = tipStatus
          }
        })
        extras.value.forEach((extra) => {
          const extraStatus = trackingStatuses.find(item => item.uuid === extra.uuid)?.status ?? equipmentCheckStatuses.value[extra.uuid]

          if (extraStatus) {
            initialCheckingFlowSummaryStatuses[extra.uuid] = extraStatus
          }
        })
        checkingFlowSummaryStatuses.value = initialCheckingFlowSummaryStatuses
        setDamageContentStatus(trackingStatuses.find(item => item.uuid === DAMAGE_CONTENT_UUID)?.status ?? '')

        return {
          ...page,
          id: OnboardingPageType.SUMMARY,
          type: OnboardingPageType.SUMMARY,
          name: pageName,
          index: pageIndex,
          disabled: !canStartChecking,
          visited: status.seen,
          seen: status.seen,
          meta: {
            title: pageName,
          },
          params: status.params,
          version: 1,
        }
      }
    }).filter(Boolean) as PageWithTracking[]

    checkingPages.value = [
      ...pages,
      {
        id: OnboardingPageType.CONGRATULATION,
        type: OnboardingPageType.CONGRATULATION,
        name: $i18n.t('congratulationPage.title'),
        index: initialIndex + pages.length,
        disabled: !canStartChecking,
        visited: pages.every(page => page.seen),
        seen: pages.every(page => page.seen),
        meta: {
          title: $i18n.t('congratulationPage.title'),
        },
        params: {},
        version: 1,
      },
    ]
  }
  const finalCheckPage = computed(() => {
    return checkingPages.value.find(page => page.type === OnboardingPageType.FINAL_CHECK)
  })
  const equipmentCheckPage = computed(() => {
    return checkingPages.value.find(page => page.type === OnboardingPageType.EQUIPMENT_CHECK)
  })
  const tips = computed(() => {
    return fullTips.value.filter((tip) => {
      if (tip.manufacturers.length === 0) {
        return true
      }

      return tip.manufacturers.some((manufacturer) => {
        return manufacturersStore.bookingManufacturer?.id === manufacturer
      })
    })
  })
  const damageContentStatus = computed(() => checkingFlowSummaryStatuses.value[DAMAGE_CONTENT_UUID])
  const setDamageContentStatus = (status: TrackingTipStatus) => checkingFlowSummaryStatuses.value[DAMAGE_CONTENT_UUID] = status
  const isVersion2 = computed(() => {
    return checkingPages.value.some((page) => page.type === OnboardingPageType.SUMMARY)
  })
  const isCheckingFlowFinished = computed(() => {
    const summaryPage = checkingPages.value.find(page => page.type === OnboardingPageType.SUMMARY)

    return summaryPage?.seen ?? false
  })
  const shouldHideStatusButtons = computed(() => {
    return isCheckingFlowFinished.value || isPickedUp.value
  })

  return {
    initCheckingPages,
    checkingPages,
    finalCheckPage,
    equipmentCheckPage,
    checkingFlowContentStatuses,
    equipmentCheckStatuses,
    checkingFlowSummaryStatuses,
    damageContentStatus,
    setDamageContentStatus,
    shouldHideStatusButtons,
    isVersion2,
    tips,
    extras,
  }
}
