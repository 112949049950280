import { Ref } from 'vue'
import { OnboardingPage, OnboardingPageType } from '~/services/entities/onboarding.entity'

export interface Page {
  id?: string,
  index: number,
  name: string,
  type: OnboardingPageType,
  content?: OnboardingPage,
  meta: {
    title: string,
  }
}

const currentPageId: Ref<string> = ref('1')
const pages: Ref<Page[]> = ref([])
const finalCheckPageV1: Ref<Page | undefined> = ref()
const finalCheckPageV2: Ref<Page | undefined> = ref()

export const transformOnboardingPageListToPages = (pages: OnboardingPage[]) => {
  return pages.map((page, index) => ({
    id: String(index + 1),
    index,
    name: `${index + 1}. ${page.name}`,
    type: page.type,
    content: page,
    version: page.version,
    meta: {
      title: page.name,
    }
  }))
}

export default function () {
  const { $i18n } = useNuxtApp()

  const initPages = (_pages: OnboardingPage[], initialPageId: string) => {
    const tutorialPages = _pages.filter(page => page.type === OnboardingPageType.ONBOARDING)
    const mapped = transformOnboardingPageListToPages(tutorialPages)

    pages.value = [
      ...mapped,
    ]
    const finalCheckPageV1Data = _pages.find(page => page.type === OnboardingPageType.FINAL_CHECK && page.version === 1)
    if (finalCheckPageV1Data) {
      finalCheckPageV1.value = {
        id: OnboardingPageType.FINAL_CHECK,
        index: 9999,
        name: $i18n.t('lastChecksPage.title'),
        type: OnboardingPageType.FINAL_CHECK,
        content: finalCheckPageV1Data,
        meta: {
          title: $i18n.t('lastChecksPage.title'),
        }
      }
    }

    const finalCheckPageV2Data = _pages.find(page => page.type === OnboardingPageType.FINAL_CHECK && page.version === 2)
    if (finalCheckPageV2Data) {
      finalCheckPageV2.value = {
        id: OnboardingPageType.FINAL_CHECK_V2,
        index: 9999,
        name: $i18n.t('lastChecksPage.title'),
        type: OnboardingPageType.FINAL_CHECK_V2,
        content: finalCheckPageV2Data,
        meta: {
          title: $i18n.t('lastChecksPage.title'),
        }
      }
    }

    currentPageId.value = initialPageId
  }

  const getPageName = (page: Page) => {
    if (page.type === OnboardingPageType.ONBOARDING) {
      return page.content.name
    }

    return page.name
  }

  const setPages = (_pages: Page[]) => {
    pages.value = _pages.map((page, index) => {
      const id = page.type === OnboardingPageType.ONBOARDING ? String(index + 1) : page.type

      return {
        ...page,
        id,
        name: getPageName(page)
      }
    })
  }

  const setCurrentPageId = (id: string) => {
    // TODO: check out of range
    currentPageId.value = id
  }

  const isViewingFinalCheckPage = computed(() => {
    return (currentPageId.value === OnboardingPageType.FINAL_CHECK && !!finalCheckPageV1.value)
      || (currentPageId.value === OnboardingPageType.FINAL_CHECK_V2 && !!finalCheckPageV2.value)
  })

  const nextPage = computed(() => {
    if (isViewingFinalCheckPage.value) {
      return
    }
    const currentPageIndex = pages.value.findIndex(page => page.id === currentPageId.value)
    const nextIndex = currentPageIndex + 1
    return nextIndex > pages.value.length ? null : pages.value[nextIndex]
  })

  const prevPage = computed(() => {
    if (isViewingFinalCheckPage.value) {
      return
    }
    const currentPageIndex = pages.value.findIndex(page => page.id === currentPageId.value)
    const previousIndex = currentPageIndex - 1
    return previousIndex < 0 ? null : pages.value[previousIndex]
  })

  const currentPage = computed(() => {
    if (isViewingFinalCheckPage.value) {
      return currentPageId.value === OnboardingPageType.FINAL_CHECK ? finalCheckPageV1.value : finalCheckPageV2.value
    }
    return pages.value.find(page => page.id === currentPageId.value)
  })

  return {
    initPages,
    currentPageId,
    currentPage,
    nextPage,
    prevPage,
    pages,
    setPages,
    setCurrentPageId,
  }
}
