import groupBy from 'lodash/groupBy'
import uniq from 'lodash/uniq'

import { Manufacturer, Onboarding, OnboardingPage, OnboardingPageContent, OnboardingPageLink, OnboardingPageLinkType, OnboardingPageType, TrackingStatus } from "../entities"
import { transformAxiosResponse } from "./base.repository"

const PAGE_TYPE_MAP = {
  DEFAULT: OnboardingPageType.ONBOARDING,
  EQUIPMENT: OnboardingPageType.EQUIPMENT_CHECK,
  FINAL_CHECK: OnboardingPageType.FINAL_CHECK,
  SUMMARY: OnboardingPageType.SUMMARY,
  SUPPORT_TREE: OnboardingPageType.SUPPORT,
  HELP_AND_RETURN: OnboardingPageType.HELP_AND_RETURN,
}

const serializeManufacturersToMergedManufacturers = ({ manufacturers, allManufacturers }): string[] => {
  // FIXME: handle complex situation where merged model changed
  const mappedManufacturers = manufacturers.map((manufacturer) => {
    const matchedManufacturer = allManufacturers
      .find(mergedManufacturer => mergedManufacturer.models.find(model => model.id === manufacturer.model))
    return matchedManufacturer?.id ?? null
  }) || []

  return uniq(mappedManufacturers.filter(Boolean))
}

const transformOnboardingPageContent = ({ content = {}, allManufacturers }:
    { content: any, allManufacturers: Manufacturer[] }): OnboardingPageContent => {
  const attachments = content.attachments.map(attachment => ({
    attachment: {
      id: attachment.attachment.id,
      originalFileName: attachment.attachment.originalFileName,
      url: attachment.attachment.url,
    },
    caption: attachment.caption,
  }))

  return {
    id: content.id,
    type: content.type,
    value: content.value,
    uuid: content.uuid,
    attachments,
    manufacturers: serializeManufacturersToMergedManufacturers({
      manufacturers: content.manufacturers,
      allManufacturers,
    }),
    nestedShowing: content?.nestedShowing.map(item => transformOnboardingPageContent({
      content: item,
      allManufacturers,
    })) ?? [],
    nestedNotShowing: content?.nestedNotShowing.map(item => transformOnboardingPageContent({
      content: item,
      allManufacturers,
    })) ?? [],
  }
}

const _transformGroup = ({ contents, grouped }) => {
  if (!contents) {
    return []
  }
  return contents.map((content) => {
    return {
      ...content,
      nestedShowing: _transformGroup({
        contents: (grouped[content.id] || []).filter(item => item.is_show),
        grouped,
      }),
      nestedNotShowing: _transformGroup({
        contents: (grouped[content.id] || []).filter(item => !item.is_show),
        grouped,
      }),
    }
  })
}

const transformOnboardingPage = ({ page = {}, allManufacturers }:
    { page: any, allManufacturers: Manufacturer[] }): OnboardingPage => {
  const pageType = page.type === OnboardingPageType.FINAL_CHECK_V2 ? OnboardingPageType.FINAL_CHECK : page.type
  const pageVersion = page.type === OnboardingPageType.FINAL_CHECK_V2 ? 2 : 1

  const pageLinks: OnboardingPageLink[] = page.onboarding_page_links
    .filter(link => link.type === OnboardingPageLinkType.HELPER_LINKS)
    .map((link) => ({
      id: link.id,
      url: link.url,
      text: link.text,
      type: link.type,
      manufacturers: serializeManufacturersToMergedManufacturers({
        manufacturers: link.manufacturers,
        allManufacturers,
      })
    }))

  const grouped = groupBy(page.onboarding_page_contents, 'parent')
  const groupedContents = _transformGroup({
    contents: grouped.null,
    grouped,
  })
  const contents = groupedContents.map(item => transformOnboardingPageContent({
    content: item,
    allManufacturers,
  }))

  return {
    id: page.id,
    uuid: page.uuid,
    name: page.name,
    position: page.position,
    type: PAGE_TYPE_MAP[pageType],
    version: pageVersion,
    pageLinks,
    contents,
  }
}

const transformLinks = (links: any[] = []) => {
  return links.map((link) => ({
    id: link.id,
    url: link.url,
    text: link.text,
    type: link.type,
    manufacturers: link.manufacturers.map(manufacturer => manufacturer.model),
  }))
}

const getMergedManufacturerModels = (manufacturers, manufacturerMergeModels) => {
  const filteredManufacturers = manufacturers.filter(manufacturer => manufacturer.model !== 'dummy_model')
  const rootManufacturers = filteredManufacturers
    .filter(manufacturer => !manufacturer.merge_model)
    .map((manufacturer) => {
      const model = manufacturer.name.split(' - ')?.[0] ?? manufacturer.name

      return {
        value: manufacturer.model,
        models: [manufacturer],
        text: `${model} - ${manufacturer.model_name.split(' - ')[0]}`,
      }
    })

  const groupedMergeManufacturers = groupBy(filteredManufacturers.filter(manufacturer => manufacturer.merge_model), 'merge_model')
  const mergedManufacturers = Object.keys(groupedMergeManufacturers).map(model => ({
    value: model,
    models: groupedMergeManufacturers[model],
    text: `${groupedMergeManufacturers[model][0].name} - ${manufacturerMergeModels[model].model_name}`
  }))

  const mergedManufacturerModels = [
    ...rootManufacturers,
    ...mergedManufacturers,
  ].sort((manufacturer1, manufacturer2) => {
    return manufacturer1.text > manufacturer2.text ? 1 : -1
  })

  return mergedManufacturerModels.map(manufacturer => ({
    id: manufacturer.value,
    modelName: manufacturer.text,
    models: manufacturer.models.map(model => ({ id: model.model, modelName: `${model.name} - ${model.model_name}` })),
  }))
}

const transformDataToOnboarding = (data: any = {}): Onboarding => {
  const linksContent = data.onboarding_pages.find(page => page.type === OnboardingPageType.LINKS)
  const manufacturers = getMergedManufacturerModels(data.manufacturers, data.manufacturer_merge_models)

  const onboardingPages = data.onboarding_pages
    .filter((page) => {
      return ![OnboardingPageType.LINKS].includes(page.type)
    })
    .map(page => transformOnboardingPage({ page, allManufacturers: manufacturers }))
    .sort((pageA, pageB) => pageA.position - pageB.position)

  return {
    id: data.id,
    name: data.name,
    slug: data.slug,
    onboardingPages,
    manufacturers,
    links: transformLinks(linksContent.onboarding_page_links),
    pdf: data.pdf_url,
    supportTreeRoots: data.support_tree_roots,
    hasSupportTrees: !!data.support_tree_roots && data.support_tree_roots.length > 0
  }
}

export const getBySlug = async (apiEngine, slug: String) => {
  const { response } = await apiEngine.call('onBoarding.fetchBySlug', { slug })
  return transformAxiosResponse(response, transformDataToOnboarding)
}

export const getBySlugPublic = async (apiEngine, slug: String) => {
  const { response } = await apiEngine.call('onBoarding.fetchBySlugPublic', { slug })
  return transformAxiosResponse(response, transformDataToOnboarding)
}

export const getTrackingStatus = async (apiEngine, bookingNumber: String) => {
  const { response } = await apiEngine.call('onBoarding.fetchTrackingStatus', { bookingNumber })
  return transformAxiosResponse(response, (data) => data as TrackingStatus[])
}

export const putTrackingStatus = async (apiEngine, payload: { pageId: Number, params: Object }, bookingNumber: String) => {
  const { pageId, params = {} } = payload
  const { response, error } = await apiEngine.call('onBoarding.putTrackingStatus', { id: pageId, bookingNumber }, {
    data: { ...params },
  })

  if (response) {
    return transformAxiosResponse(response, (data) => data)
  }

  if (error) {
    return error
  }
}

export const checkTrackingContent = async (apiEngine, payload: { pageUuid: string, contentUuid: string, status: string }, bookingNumber: String) => {
  const { pageUuid, contentUuid, status } = payload
  const { response, error } = await apiEngine.call('onBoarding.checkTrackingContent', {
    bookingNumber,
   }, {
    data: {
      page_uuid: pageUuid,
      content_uuid: contentUuid,
      status,
    },
  })
  if (error) {
    throw error
  }
  return transformAxiosResponse(response, (data) => data)
}

export const approveTrackingContent = async (apiEngine, payload: { pageUuid: string, contentUuid: string, status: string }, bookingNumber: String) => {
  const { pageUuid, contentUuid, status } = payload
  const { response, error } = await apiEngine.call('onBoarding.approveTrackingContent', {
    bookingNumber,
   }, {
    data: {
      page_uuid: pageUuid,
      content_uuid: contentUuid,
      status,
    },
  })
  if (error) {
    throw error
  }
  return transformAxiosResponse(response, (data) => data)
}

export const trackStartFinalChecks = async (apiEngine, bookingNumber: String) => {
  const { response } = await apiEngine.call('onBoarding.trackStartFinalChecks', {
    bookingNumber,
   }, {
    data: {},
  })
  return transformAxiosResponse(response, (data) => data)
}