import { OnboardingPageType } from '~/services/entities'
import type { PageWithTracking } from './types'
import { useCheckingFlow } from './useCheckingFlow'

const returnPages = ref<PageWithTracking[]>([])

export const useReturnFlow = () => {
  const { $i18n } = useNuxtApp()

  const { checkingPages } = useCheckingFlow()

  const initReturnPages = ({ initialIndex }: {
    initialIndex: number,
  }) => {
    returnPages.value = [
      {
        id: OnboardingPageType.THANK_YOU,
        type: OnboardingPageType.THANK_YOU,
        name: $i18n.t('thankYouPage.title'),
        index: initialIndex + 1,
        disabled: !checkingPages.value.every(page => page.seen),
        visited: checkingPages.value.every(page => page.seen),
        seen: checkingPages.value.every(page => page.seen),
        meta: {
          title: $i18n.t('thankYouPage.title')
        },
        params: {},
        version: 1,
      }
    ]
  }
  const isReturnPage = () => {}

  return {
    initReturnPages,
    isReturnPage,
    returnPages,
  }
}
