<template>
  <div class="global-loading" data-testid="global-loading">
    <div class="global-loading__backdrop" />
    <div class="global-loading__indicator">
      <div class="relative">
        <img src="/img/loading.svg">
        <div class="global-loading__inner-icon">
          <img src="/img/loading-car.png">
        </div>
      </div>
      <div class="text-center font-bold">
        {{ $t('common.loading') }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.global-loading {
  @apply fixed flex inset-0 items-center justify-center flex-col;
  z-index: 100;
}
.global-loading__backdrop {
  @apply absolute inset-0 bg-white z-0;
  opacity: 0.8;
}
.global-loading__indicator {
  @apply flex flex-col justify-center items-center z-10;
}
.global-loading__inner-icon {
  @apply absolute top-1/2 left-1/2 pb-6 transform -translate-x-1/2 -translate-y-1/2;
}
</style>
