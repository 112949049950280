export enum ExtraItemType {
  FLEXIBLE_PICKUP_TIME = 'flexible_pickup_time',
  FLEXIBLE_RETURN_TIME = 'flexible_return_time',
  DOG_FLAT_RATE = 'pet'
}

export enum BookingSubType {
  BUSINESS_TRIP = 'BUSINESS_TRIP'
}

export interface ExtraItem {
  name: string
  identifier: string
  quantity: number
  coverImage: string
  mandatory: boolean
  type?: ExtraItemType
  translations: Object
  uuid: string
}

export enum BookingState {
  PREPARED = 'PREPARED',
  RELEASED = 'RELEASED',
  RESERVED = 'RESERVED',
  CANCELLED = 'CANCELLED',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_AUTHORISATION = 'PENDING_AUTHORISATION',
  FAILED_PAYMENT = 'FAILED_PAYMENT',
  ON_GOING = 'ON_GOING',
  RETURNED = 'RETURNED',
}

export interface Insurance {
  id: number,
  name: string,
  uuid: string,
}

export interface Booking {
  bookingNumber: string
  car: {
    id: number
  }
  state: BookingState,
  extras: ExtraItem[],
  petExtras: ExtraItem[],
  agreedPickupDatetime: string
  agreedReturnDatetime: string
  pickupStation: string
  customerLanguage: string
  firstTrackedManufacturerModel: string
  manufacturerModel: string
  customer: Object,
  drivers: Object[],
  insurance: Insurance
  deposit: boolean
  subType: BookingSubType
  isSubscription: boolean
}
