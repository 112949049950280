import isEmpty from 'lodash/isEmpty.js'
import { useToast } from 'vue-toastification'

import type { IToastProps } from '~/models'
import RsToast from '~/components/core/RsToast.vue'
import RsToastSuccess from '~/components/core/RsToastSuccess.vue'
import RsToastClose from '~/components/core/RsToastClose.vue'

const TOAST_COMPONENTS = {
  success: RsToastSuccess,
  error: RsToast,
}

export default function () {
  const toast = useToast()

  const showToast = (type: string, message: string, otherProps: IToastProps = null, timeout = 10000) => {
    const content = {
      component: TOAST_COMPONENTS[type],
      props: {
        message,
        ...(!isEmpty(otherProps) && otherProps),
      },
    }
    toast(content, {
      timeout,
      closeOnClick: false,
      closeButton: RsToastClose,
      toastClassName: [
        'rs-toast',
        `rs-toast--${type}`,
      ],
    })
  }

  return {
    showToast,
  }
}
