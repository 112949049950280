<template>
  <div class="rs-header">
    <div class="flex items-center justify-between">
      <RsLink :to="!isLoading ? caHomepage : ''" external :disabled="isPublic">
        <RsLogo />
      </RsLink>

      <div class="flex items-center">
        <DesktopNavbar v-if="isDesktop" />
        <MobileNavbar v-else />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCustomerStore } from '~/stores/customer.store';
import useBreakpoints from '~/composables/useBreakpoints'
import RsLogo from '~/components/core/RsLogo.vue'
import DesktopNavbar from '~/components/navbar/DesktopNavbar.vue'
import MobileNavbar from '~/components/navbar/MobileNavbar.vue'
import { useOnboardingFlow } from '~/composables/usecases';


defineProps({
  isLoading: Boolean,
})

const { isPublic } = useCustomerStore()
const { isDesktop } = useBreakpoints()
const { caHomepage } = useOnboardingFlow()
</script>

<style scoped>
.rs-header {
  @apply border-b-2 border-rs-primary p-4 md:px-8 relative;
}
/* .rs-header__wimpel {
  @apply w-full h-10 sm:h-16 absolute left-0 right-0 top-full;
  background: url('/img/wimpel.svg') center repeat-x;
} */
</style>
