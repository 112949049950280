import { LOCALE_NAMES } from '~/locales'
import { BOOKING_STATE } from '~/services/constants'
import useDrivers from '~/composables/queries/useDrivers'
import { useOnboardingFlow } from '~/composables/usecases'

export function useLocalePathEntity () {
  const { $router } = useNuxtApp()
  const { i18n, currentLocale } = useLocale()
  const localePath = useLocalePath()
  const { handleModal } = useModal()
  const { startLoading } = useLoading()
  const { updateMainDriver } = useDrivers()
  const { showToast } = useToast()
  const { booking, bookingVersion } = useOnboardingFlow()

  const getLocalePath = (locale: string) => {
    const { name, path, query } = $router.currentRoute.value

    if (name) {
      const newLocalePath = localePath({ path, query }, locale)
      return newLocalePath
    } else {
      return `/${locale}${path.replace(`/${currentLocale.value}`, '')}`
    }
  }

  const getLocaleName = (locale: string) => {
    return LOCALE_NAMES[locale]
  }

  const redirect = (locale: string) => {
    const localizedPath = getLocalePath(locale)
    const { query } = $router.currentRoute.value
    $router.push({ path: localizedPath, query })

    setTimeout(() => $router.go(), 1000)
  }

  const selectNewLanguage = async (localeCode: string) => {
    if (currentLocale.value === localeCode) {
      return
    }

    if (booking.value && booking.value.state === BOOKING_STATE.RESERVED) {
      const { isConfirmed } = await handleModal(
        'modal-confirm-language',
        {
          newLanguage: getLocaleName(localeCode),
        },
      )

      startLoading()
      if (isConfirmed && booking.value) {
        const { response, error } = await updateMainDriver(
          booking.value.bookingNumber,
          {
            ...booking.value.customer,
            language: localeCode,
          },
          booking.value.drivers,
          bookingVersion.value
        )

        if (response) {
          showToast('success', i18n.t('common.changesSuccess'))
        } else {
          if (error.status === 409) {
            showToast('error', i18n.t('error.conflictedBookingError'))
            return
          }

          showToast('error', i18n.t('common.changeError'))
        }
      }
    }

    redirect(localeCode)
  }

  return {
    getLocaleName,
    selectNewLanguage,
    getLocalePath,
  }
}
