import { defineStore } from 'pinia'

const LOGIN_ROLES = {
  PARTNER: 'partner',
  BACKOFFICE: 'bo',
  CUSTOMER: 'customer',
}

export const useCustomerStore = defineStore('customer', {
  state: () => ({
    role: LOGIN_ROLES.CUSTOMER,
    public: false,
    booking_number: ''
  }),
  actions: {
    setRole (role: string) {
      const isValidRole = Object.values(LOGIN_ROLES).includes(role)
      if (isValidRole) {
        this.role = role
      } else {
        this.role = LOGIN_ROLES.CUSTOMER
      }
    },
    setPublicMode (isPublic: boolean) {
      this.public = isPublic
    },
    setBookingNumber (bookingNumber: string) {
      this.booking_number = bookingNumber
    }
  },
  getters: {
    bookingNumber: (state) => state.booking_number,
    isCustomer: (state) => state.role === LOGIN_ROLES.CUSTOMER,
    isPartner: (state) => state.role === LOGIN_ROLES.PARTNER,
    isBackOffice: (state) => state.role === LOGIN_ROLES.BACKOFFICE,
    isPublic: (state) => state.public
  },
  persist: {
    key: 'ct-customer',
  },
})
