import { defineNuxtPlugin } from '#app/nuxt'
import { LazyRsButton, LazyRsCard, LazyRsField, LazyRsGlobalLoading, LazyRsHeadline, LazyRsHint, LazyRsInput, LazyRsLink, LazyRsModal, LazyIcons, LazySvgoIcArrowLeft, LazySvgoIcArrowRight, LazySvgoIcCertificate, LazySvgoIcCheck, LazySvgoIcCheckboxChecked, LazySvgoIcCheckbox, LazySvgoIcChevronDown, LazySvgoIcChevronLeft, LazySvgoIcCloseBlack, LazySvgoIcClose, LazySvgoIcDownload, LazySvgoIcInfo, LazySvgoIcLanguage, LazySvgoIcNavbar, LazySvgoIcNoBookedEquipments, LazySvgoIcQuestionCircle, LazySvgoIcRadioSelected, LazySvgoIcRadioUnselected, LazySvgoIcRadio, LazySvgoIcRefresh, LazySvgoIcSearch, LazySvgoIcShare, LazySvgoIcTrailing, LazySvgoIcUser } from '#components'
const lazyGlobalComponents = [
  ["RsButton", LazyRsButton],
["RsCard", LazyRsCard],
["RsField", LazyRsField],
["RsGlobalLoading", LazyRsGlobalLoading],
["RsHeadline", LazyRsHeadline],
["RsHint", LazyRsHint],
["RsInput", LazyRsInput],
["RsLink", LazyRsLink],
["RsModal", LazyRsModal],
["Icons", LazyIcons],
["SvgoIcArrowLeft", LazySvgoIcArrowLeft],
["SvgoIcArrowRight", LazySvgoIcArrowRight],
["SvgoIcCertificate", LazySvgoIcCertificate],
["SvgoIcCheck", LazySvgoIcCheck],
["SvgoIcCheckboxChecked", LazySvgoIcCheckboxChecked],
["SvgoIcCheckbox", LazySvgoIcCheckbox],
["SvgoIcChevronDown", LazySvgoIcChevronDown],
["SvgoIcChevronLeft", LazySvgoIcChevronLeft],
["SvgoIcCloseBlack", LazySvgoIcCloseBlack],
["SvgoIcClose", LazySvgoIcClose],
["SvgoIcDownload", LazySvgoIcDownload],
["SvgoIcInfo", LazySvgoIcInfo],
["SvgoIcLanguage", LazySvgoIcLanguage],
["SvgoIcNavbar", LazySvgoIcNavbar],
["SvgoIcNoBookedEquipments", LazySvgoIcNoBookedEquipments],
["SvgoIcQuestionCircle", LazySvgoIcQuestionCircle],
["SvgoIcRadioSelected", LazySvgoIcRadioSelected],
["SvgoIcRadioUnselected", LazySvgoIcRadioUnselected],
["SvgoIcRadio", LazySvgoIcRadio],
["SvgoIcRefresh", LazySvgoIcRefresh],
["SvgoIcSearch", LazySvgoIcSearch],
["SvgoIcShare", LazySvgoIcShare],
["SvgoIcTrailing", LazySvgoIcTrailing],
["SvgoIcUser", LazySvgoIcUser],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
