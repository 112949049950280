<template >
  <div v-if="!isPublic && !isBackOffice" class="flex mr-6">
    <Popper
      placement="bottom-end"
      offset-distance="0"
    >
      <div class="flex items-center cursor-pointer">
        <IcUser class="text-black text-xl" width="22" />
      </div>

      <template #content="{ close }">
        <div class="locale-switcher">
          <div class="locale-switcher__header">
            <IcClose width="36" class="locale-switcher__ic-close" @click="close" />
            <span class="font-bold"> {{ $t('navbar.profile') }} </span>
          </div>
          <div class="p-4 pb-10">
            <LogoutButton @close="close"/>
          </div>
        </div>
      </template>
    </Popper>
  </div>
</template>

<script setup lang="ts">
import Popper from 'vue3-popper'
import { useCustomerStore } from '~/stores/customer.store'
import { IcUser, IcClose } from '~/components/common/icons';
import LogoutButton from '~/components/navbar/LogoutButton.vue'

const { isPublic, isBackOffice } = useCustomerStore()
</script>

<style scoped>
.locale-switcher {
  @apply bg-white flex flex-col w-64 divide-y divide-gray-200;
}
.locale-switcher__header {
  @apply relative text-center border-b border-gray-400 p-4;
}
.locale-switcher__ic-close {
  @apply cursor-pointer absolute left-4 top-2.5 text-rs-primary;
}
.locale-switcher--popper :deep(.popper) {
  @apply !rounded-md overflow-hidden !shadow !bg-white;
  @apply !my-3;
  border: 1px solid #ddd !important;
}
</style>
