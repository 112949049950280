import { useCustomerStore } from '~/stores/customer.store';
import useBookingRepository from '~/composables/repositories/useBookingRepository'
import { type Booking } from '~/services/entities'
import useSessionRepository from '../repositories/useSessionRepository'

const booking = ref<Booking | null>(null)
const bookingVersion = ref<String>('')

export default function () {
  const { fetchBooking } = useBookingRepository()
  const { fetchSession } = useSessionRepository()
  const { setBookingNumber } = useCustomerStore()

  const initBooking = async () => {
    const sessionResponse = await fetchSession()
    setBookingNumber(sessionResponse.data.bookingNumber)

    const response = await fetchBooking(sessionResponse.data.bookingNumber)
    booking.value = response.data
    bookingVersion.value = response.bookingVersion

    
  }

  return {
    initBooking,
    booking,
    bookingVersion
  }
}
