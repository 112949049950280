import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

export default function () {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isLgDesktop = breakpoints.greater('lg')
  const isDesktop = breakpoints.greater('md')
  const isMobile = breakpoints.smaller('sm')

  return {
    breakpoints,
    isLgDesktop,
    isDesktop,
    isMobile,
  }
}
