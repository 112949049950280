import { getApiRegex } from '~/services/api/apiRegex'

export default {
  'onBoarding.fetchBySlug': {
    uri: (locale: string) => `/${locale}/onboardings/:slug`,
    method: 'get',
    regex: getApiRegex('\/onboardings(\/(\\w|-)+)'),
  },
  'onBoarding.fetchBySlugPublic': {
    uri: (locale: string) => `/${locale}/onboardings/:slug`,
    method: 'get',
    regex: getApiRegex('\/onboardings(\/(\\w|-)+)'),
  },
  'onBoarding.fetchTrackingStatus': {
    uri: '/customer-account/onboarding-page/trackings/:bookingNumber',
    method: 'get',
    regex: getApiRegex('\/customer-account\/onboarding-page\/trackings(\/\\d+)', false),
  },
  'onBoarding.putTrackingStatus': {
    uri: '/customer-account/onboarding-page/:id/trackings/:bookingNumber',
    method: 'put',
    regex: getApiRegex('\/customer-account\/onboarding-page(\/\\d+)\/trackings(\/\\d+)', false),
  },
  'onBoarding.checkTrackingContent': {
    uri: '/customer-account/onboarding-content/:bookingNumber/checking',
    method: 'post',
    regex: getApiRegex('\/customer-account\/onboarding-content(\/\\d+)\/checking', false),
  },
  'onBoarding.approveTrackingContent': {
    uri: '/customer-account/onboarding-content/:bookingNumber/approval',
    method: 'post',
    regex: getApiRegex('\/customer-account\/onboarding-content(\/\\d+)\/approval', false),
  },
  'onBoarding.trackStartFinalChecks': {
    uri: '/customer-account/final-check/:bookingNumber/start',
    method: 'post',
    regex: getApiRegex('\/customer-account\/final-check(\/\\d+)\/start', false)
  },
}
