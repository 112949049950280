import IcCheck from '~/assets/icons/ic-check.svg'
import IcChevronDown from '~/assets/icons/ic-chevron-down.svg'
import IcChevronLeft from '~/assets/icons/ic-chevron-left.svg'
import IcClose from '~/assets/icons/ic-close.svg'
import IcCloseBlack from '~/assets/icons/ic-close-black.svg'
import IcLanguage from '~/assets/icons/ic-language.svg'
import IcNavbar from '~/assets/icons/ic-navbar.svg'
import IcTrailing from '~/assets/icons/ic-trailing.svg'
import IcArrowLeft from '~/assets/icons/ic-arrow-left.svg'
import IcArrowRight from '~/assets/icons/ic-arrow-right.svg'
import IcSearch from '~/assets/icons/ic-search.svg'
import IcInfo from '~/assets/icons/ic-info.svg'
import IcNoBookedEquipments from '~/assets/icons/ic-no-booked-equipments.svg'
import IcCheckboxChecked from '~/assets/icons/ic-checkbox-checked.svg'
import IcCheckbox from '~/assets/icons/ic-checkbox.svg'
import IcShare from '~/assets/icons/ic-share.svg'
import IcQuestionCircle from '~/assets/icons/ic-question-circle.svg'
import IcDownload from '~/assets/icons/ic-download.svg'
import IcUser from '~/assets/icons/ic-user.svg'
import IcRadio from '~/assets/icons/ic-radio.svg'
import IcRadioSelected from '~/assets/icons/ic-radio-selected.svg'
import IcRadioUnselected from '~/assets/icons/ic-radio-unselected.svg'
import IcRefresh from '~/assets/icons/ic-refresh.svg'

// TODO: use nuxt icon

export {
  IcCheck,
  IcChevronDown,
  IcChevronLeft,
  IcClose,
  IcCloseBlack,
  IcLanguage,
  IcNavbar,
  IcTrailing,
  IcArrowLeft,
  IcArrowRight,
  IcSearch,
  IcInfo,
  IcNoBookedEquipments,
  IcCheckbox,
  IcCheckboxChecked,
  IcShare,
  IcQuestionCircle,
  IcDownload,
  IcUser,
  IcRadio,
  IcRadioSelected,
  IcRadioUnselected,
  IcRefresh,
}
