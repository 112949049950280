import {
  getByBookingNumber,
  checkVehicleChange as _checkVehicleChange,
  checkDepositMade as _checkDepositMade,
} from '~/services/repositories/booking.repository'

export default function () {
  const { $api } = useNuxtApp()

  const fetchBooking = async (bookingNumber: string) => {
    return getByBookingNumber($api, bookingNumber)
  }

  const checkVehicleChange = async (bookingNumber: string, carId: number) => {
    return _checkVehicleChange($api, bookingNumber, carId)
  }

  const checkDepositMade = async (bookingNumber: string) => {
    return _checkDepositMade($api, bookingNumber)
  }

  return {
    fetchBooking,
    checkVehicleChange,
    checkDepositMade,
  }
}
