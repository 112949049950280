import dayjs from "dayjs"
import { type Booking, type ExtraItem, ExtraItemType } from "../entities"
import { transformAxiosResponse } from "./base.repository"

const transformExtraItem = (data: any = {}): ExtraItem => {
  const cover = data.extra.extra_images.find(image => image.cover)

  return {
    name: data.extra.name,
    coverImage: cover?.image?.url,
    quantity: data.quantity,
    identifier: data.identifier,
    mandatory: data.extra.mandatory,
    type: data.extra.type,
    uuid: data.extra.uuid,
    translations: data.extra?.equipment_check?.translations || {}
  }
}

const HIDDEN_EQUIPMENTS = [
  'extra_5dcad690039384.99529780', // Service fee
  'extra_6184a3443dd643.32513829', // Dog flat-rate
  'extra_62eb9fc46cd086.06183107', // Request for Drop-Off in Las Vegas
  'extra_62eba0491f18b0.01804337', // Request for Drop-Off in San Francisco
  'extra_62f51c428d6168.08033819', // Unlimited Mileage
  'extra_63a952592fe621.13296182', // Cancellation option Flex
  'extra_63ed11fd8b8b60.98428017', // spots campfire card
  'extra_63ef7c6d1e70f9.37100434', // Service fee (USA & CANADA)
  'extra_6527d0c5d41cc9.68266744', // One Way Fee
  'extra_655df71d0ccf77.14387685', // Dog Flat-Rate (US & CA)
  'extra_65e552fc602d35.17123171', // Special Pickup Day
  'extra_65e553400b0526.54709230', // Special Return Day
]
const transformDataToBooking = (data: any = {}): Booking => {
  return {
    bookingNumber: data.booking_number,
    car: {
      id: data.car?.id,
    },
    extras: data.extras
      .filter(extra =>
        !extra.extra.mandatory
        && ![ExtraItemType.FLEXIBLE_PICKUP_TIME, ExtraItemType.FLEXIBLE_RETURN_TIME].includes(extra.type)
        && !HIDDEN_EQUIPMENTS.includes(extra.extra.uuid)
      )
      .map(transformExtraItem),
    petExtras: data.extras.filter((extra) => extra.type === ExtraItemType.DOG_FLAT_RATE),
    agreedPickupDatetime: dayjs(data.pickup_datetime).startOf('day').format(),
    agreedReturnDatetime: dayjs(data.return_datetime).startOf('day').format(),
    pickupStation: data.pickup_station.country,
    state: data.state,
    customerLanguage: data.customer.settings.find((setting) => setting.key === 'communication_language')?.value ?? 'en',
    manufacturerModel: data.car.manufacturer_model,
    firstTrackedManufacturerModel: data.first_tracking_manufacturer?.manufacturer_model,
    customer: data.customer,
    drivers: data.drivers,
    insurance: {
      id: data.insurance.id,
      name: data.insurance.name,
      uuid: data.insurance.uuid,
    },
    deposit: !!data.deposit,
    subType: data.booking_subtype,
    isSubscription: !!data.subscription_duration, // subscription checking logic in BO
  }
}

export const getByBookingNumber = async (apiEngine, bookingNumber: String) => {
  const { response } = await apiEngine.call('booking.get', { bookingNumber })
  return transformAxiosResponse(response, transformDataToBooking)
}

export const checkVehicleChange = async (apiEngine, bookingNumber: string, carId: number) => {
  const { response } = await apiEngine.call('booking.checkVehicleChange', { bookingNumber, carId })
  return transformAxiosResponse(response, (data) => data)
}

export const checkDepositMade = async (apiEngine, bookingNumber: string) => {
  const { response } = await apiEngine.call('booking.checkDepositMade', { bookingNumber })
  return transformAxiosResponse(response, (data) => ({ depositMade: !!data.deposit_made }))
}
