import { type OnboardingPage, OnboardingPageType, type TrackingStatus } from '~/services/entities'
import { type PageWithTracking } from './types'
import { transformOnboardingPageListToPages } from '../useTutorialPageNavigation'

const tutorialPages = ref<PageWithTracking[]>([])

export const useTutorialFlow = () => {
  const { $i18n } = useNuxtApp()

  const initTutorialPages = ({ onboardingPages, trackings, hasSupportTrees }: {
    onboardingPages: OnboardingPage[],
    trackings: TrackingStatus[]
    hasSupportTrees: Boolean
  }) => {
    const pages = transformOnboardingPageListToPages(
      onboardingPages.filter(page => page.type === OnboardingPageType.ONBOARDING)
    ).map((page) => {
        const status = trackings.find(status => status.uuid === page.content?.uuid)!

        return {
          ...page,
          visited: status.seen,
          seen: status.seen,
          params: status.params,
        }
      })

    const supportTreePage = onboardingPages.find(page => page.type === OnboardingPageType.SUPPORT)
    const supportTreeStatus = trackings.find(status => status.uuid === supportTreePage?.uuid)
    const staticPages = [
      ...(hasSupportTrees ? [{
        id: OnboardingPageType.SUPPORT,
        type: OnboardingPageType.SUPPORT,
        name: 'supportPage.title',
        meta: {
          title: $i18n.t('supportPage.title'),
        },
        visited: supportTreeStatus?.seen,
        seen: supportTreeStatus?.seen,
        content: {
          id: supportTreePage?.id
        }
      }]: []),
      {
        id: OnboardingPageType.CERTIFICATE,
        type: OnboardingPageType.CERTIFICATE,
        name: 'certificatePage.title',
        meta: {
          title: $i18n.t('certificatePage.title'),
        }
      }
    ]

    tutorialPages.value = staticPages.reduce(
      (resultPages, staticPage) => {
        return [
          ...resultPages,
          {
            ...staticPage,
            index: resultPages.length,
            name: `${resultPages.length + 1}. ${$i18n.t(staticPage.name)}`,
            params: {},
            version: 1,
            ...(staticPage.type === OnboardingPageType.CERTIFICATE && {
              visited: resultPages.every(page => page.seen),
              seen: resultPages.every(page => page.seen),
            })
          }
        ]
      }, pages
    )
  }

  const isTutorialPage = () => {}

  return {
    initTutorialPages,
    isTutorialPage,
    tutorialPages,
  }
}
