import type { AxiosInstance } from 'axios'
import axios from 'axios'
import onBoardingServices from './onBoardingServices'
import bookingServices from './bookingServices'
import sessionServices from './sessionServices'
import customerServices from './customerServices'
import supportTreeServices from './supportTreeServices'
import { BOOKING_VERSION_API_HEADER } from '~/services/constants'

export const API: Record<string, any> = {
  ...onBoardingServices,
  ...bookingServices,
  ...sessionServices,
  ...customerServices,
  ...supportTreeServices
}

const API_METHODS = ['post', 'get', 'patch', 'delete', 'put']

export default class Request {
  token: string
  axiosInstance: AxiosInstance

  constructor (token = '') {
    this.token = token
    this.axiosInstance = this.getAxiosInstance()
  }

  getAxiosInstance () {
    const config = useRuntimeConfig()
    let baseURL = '/api'
    if (process.server) {
      baseURL = config.public.API_HOST
    }
    return axios.create({ baseURL })
  }

  static call (endpoint: string, routeParams: any, config: any) {
    return new Request().call(endpoint, routeParams, config)
  }

  call (endpoint: string, routeParams: any, { params: query, paramsSerializer, data, ...config }: any = {}) {
    if (!(endpoint in API)) {
      throw new Error(`Not implemented: ${endpoint}`)
    }

    const target = API[endpoint]

    if (!API_METHODS.includes(target.method)) {
      throw new Error('Method must be [get, post, patch, delete, put]')
    }

    // URI can contains url params and query string, like "/resoureces/:resource_id?foo=:foo_value"
    // This snippet will replace :resource_id and :foo_value with values from "params" object
    const nuxtApp = useNuxtApp()
    const { $i18n } = nuxtApp.vueApp.config.globalProperties
    let uri = typeof target.uri === 'function'
      ? target.uri($i18n.locale)
      : target.uri

    for (const key in routeParams) {
      const regex = new RegExp(`:${key}`, 'gi')
      const value = routeParams[key] || ''

      const updated = uri.replace(regex, value)
      if (uri !== updated) {
        delete routeParams[key] // Remove fields in params that appear on uri
      }

      uri = updated
    }

    this.axiosInstance.defaults.headers.common['X-Requested-Alias'] = endpoint

    if (config.bookingVersion) {
      this.axiosInstance.defaults.headers.common[BOOKING_VERSION_API_HEADER] = config.bookingVersion
    }

    if (query || data) {
      return this.axiosInstance({
        url: uri,
        data,
        params: query,
        method: target.method,
        ...config,
      })
    }

    return this.axiosInstance[target.method](uri, { ...routeParams, ...config })
  }

  setRequestInterceptor (onFulfilled, onRejected) {
    return this.axiosInstance.interceptors.request.use(onFulfilled, onRejected)
  }

  clearRequestInterceptor (id) {
    this.axiosInstance.interceptors.request.eject(id)
  }

  setResponseInterceptor (onFulfilled, onRejected) {
    return this.axiosInstance.interceptors.response.use(onFulfilled, onRejected)
  }

  clearResponseInterceptor (id) {
    this.axiosInstance.interceptors.response.eject(id)
  }
}
