import Toast from 'vue-toastification'

// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

export default defineNuxtPlugin((nuxtApp) => {
  const toastOpions = {
    position: 'top-right',
    shareAppContext: true,
    hideProgressBar: true,
    draggable: false,
    icon: false,
  }

  nuxtApp.vueApp.use(Toast, toastOpions)
})
