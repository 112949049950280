import { getSession } from '~/services/repositories/session.repository'

export default function () {
  const { $api, $router } = useNuxtApp()
  const { query } = $router.currentRoute.value

  const fetchSession = async () => {
    return getSession($api, query.token)
  }

  return {
    fetchSession,
  }
}
