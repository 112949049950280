import { default as indexsW2TaYcCzPMeta } from "/usr/src/app/pages/[slug]/index.vue?macro=true";
import { default as onboardingpLwAwErUOeMeta } from "/usr/src/app/pages/[slug]/onboarding.vue?macro=true";
import { default as pdf_45previewCkhNmDXfR1Meta } from "/usr/src/app/pages/[slug]/pdf-preview.vue?macro=true";
import { default as _91slug_938tGCft0aMGMeta } from "/usr/src/app/pages/[slug].vue?macro=true";
export default [
  {
    name: _91slug_938tGCft0aMGMeta?.name,
    path: "/en/:slug()",
    meta: _91slug_938tGCft0aMGMeta || {},
    component: () => import("/usr/src/app/pages/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "slug___en",
    path: "",
    meta: indexsW2TaYcCzPMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-onboarding___en",
    path: "onboarding",
    meta: onboardingpLwAwErUOeMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "slug-pdf-preview___en",
    path: "pdf-preview",
    meta: pdf_45previewCkhNmDXfR1Meta || {},
    component: () => import("/usr/src/app/pages/[slug]/pdf-preview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_938tGCft0aMGMeta?.name,
    path: "/fr/:slug()",
    meta: _91slug_938tGCft0aMGMeta || {},
    component: () => import("/usr/src/app/pages/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "slug___fr",
    path: "",
    meta: indexsW2TaYcCzPMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-onboarding___fr",
    path: "onboarding",
    meta: onboardingpLwAwErUOeMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "slug-pdf-preview___fr",
    path: "pdf-preview",
    meta: pdf_45previewCkhNmDXfR1Meta || {},
    component: () => import("/usr/src/app/pages/[slug]/pdf-preview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_938tGCft0aMGMeta?.name,
    path: "/:slug()",
    meta: _91slug_938tGCft0aMGMeta || {},
    component: () => import("/usr/src/app/pages/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "slug___de___default",
    path: "",
    meta: indexsW2TaYcCzPMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-onboarding___de___default",
    path: "onboarding",
    meta: onboardingpLwAwErUOeMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "slug-pdf-preview___de___default",
    path: "pdf-preview",
    meta: pdf_45previewCkhNmDXfR1Meta || {},
    component: () => import("/usr/src/app/pages/[slug]/pdf-preview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_938tGCft0aMGMeta?.name,
    path: "/de/:slug()",
    meta: _91slug_938tGCft0aMGMeta || {},
    component: () => import("/usr/src/app/pages/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "slug___de",
    path: "",
    meta: indexsW2TaYcCzPMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-onboarding___de",
    path: "onboarding",
    meta: onboardingpLwAwErUOeMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "slug-pdf-preview___de",
    path: "pdf-preview",
    meta: pdf_45previewCkhNmDXfR1Meta || {},
    component: () => import("/usr/src/app/pages/[slug]/pdf-preview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_938tGCft0aMGMeta?.name,
    path: "/pt/:slug()",
    meta: _91slug_938tGCft0aMGMeta || {},
    component: () => import("/usr/src/app/pages/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "slug___pt",
    path: "",
    meta: indexsW2TaYcCzPMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-onboarding___pt",
    path: "onboarding",
    meta: onboardingpLwAwErUOeMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "slug-pdf-preview___pt",
    path: "pdf-preview",
    meta: pdf_45previewCkhNmDXfR1Meta || {},
    component: () => import("/usr/src/app/pages/[slug]/pdf-preview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_938tGCft0aMGMeta?.name,
    path: "/es/:slug()",
    meta: _91slug_938tGCft0aMGMeta || {},
    component: () => import("/usr/src/app/pages/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "slug___es",
    path: "",
    meta: indexsW2TaYcCzPMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-onboarding___es",
    path: "onboarding",
    meta: onboardingpLwAwErUOeMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "slug-pdf-preview___es",
    path: "pdf-preview",
    meta: pdf_45previewCkhNmDXfR1Meta || {},
    component: () => import("/usr/src/app/pages/[slug]/pdf-preview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_938tGCft0aMGMeta?.name,
    path: "/it/:slug()",
    meta: _91slug_938tGCft0aMGMeta || {},
    component: () => import("/usr/src/app/pages/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "slug___it",
    path: "",
    meta: indexsW2TaYcCzPMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-onboarding___it",
    path: "onboarding",
    meta: onboardingpLwAwErUOeMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "slug-pdf-preview___it",
    path: "pdf-preview",
    meta: pdf_45previewCkhNmDXfR1Meta || {},
    component: () => import("/usr/src/app/pages/[slug]/pdf-preview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_938tGCft0aMGMeta?.name,
    path: "/sv/:slug()",
    meta: _91slug_938tGCft0aMGMeta || {},
    component: () => import("/usr/src/app/pages/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "slug___sv",
    path: "",
    meta: indexsW2TaYcCzPMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-onboarding___sv",
    path: "onboarding",
    meta: onboardingpLwAwErUOeMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "slug-pdf-preview___sv",
    path: "pdf-preview",
    meta: pdf_45previewCkhNmDXfR1Meta || {},
    component: () => import("/usr/src/app/pages/[slug]/pdf-preview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_938tGCft0aMGMeta?.name,
    path: "/nl/:slug()",
    meta: _91slug_938tGCft0aMGMeta || {},
    component: () => import("/usr/src/app/pages/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "slug___nl",
    path: "",
    meta: indexsW2TaYcCzPMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-onboarding___nl",
    path: "onboarding",
    meta: onboardingpLwAwErUOeMeta || {},
    component: () => import("/usr/src/app/pages/[slug]/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "slug-pdf-preview___nl",
    path: "pdf-preview",
    meta: pdf_45previewCkhNmDXfR1Meta || {},
    component: () => import("/usr/src/app/pages/[slug]/pdf-preview.vue").then(m => m.default || m)
  }
]
  }
]